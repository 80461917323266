<template>
  <div id="footer" class="footerGroup">
    <b-row>
      <b-col>
        <div>
          <b-row>
            <b-icon class="icons h3 mt-5" icon="telephone" />
          </b-row>
          <b-row class="mt-2">
            <a href="tel:+34917674508" class="text-center">917674508</a>
          </b-row>
        </div>
      </b-col>
      <b-col>
        <b-row
          ><b-icon  class="icons h3 mt-5" icon="envelope"
        /></b-row>
        <b-row class="mt-2"
          ><a
            href="mailto:parroquiaarmada@gmail.com"
            class="text-center"
            >parroquiaarmada@gmail.com</a>
          </b-row>
      </b-col>
      <b-col>
        <b-row><b-icon  class="icons h3 mt-5" icon="map" /></b-row>
        <b-row class="mt-2"><p class="text-center"> C. de Serrano Galvache, 7, Cdad. Lineal - 28033 Madrid, España</p></b-row>
        </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "footer-site",
  props: [],
  components: {},
  data() {
    return {
      lista: [],
      title: "",
      onlyImage: [],
      index: null,
    };
  },
  watch: {},
  methods: {},
};
</script>
<style scoped>
.row {
  margin: 0;
}
.footerGroup {
  background-color: #444;
  color: antiquewhite;
}
.icon-footer :hover {
  color: blue;
}
.youtube-icon:hover{
color: red;
cursor: pointer;
}
a{
  color: aliceblue;
}
.facebook-icon:hover{
  color:#3b5998;
  cursor: pointer;
}
</style>
