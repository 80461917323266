<template>
  <b-modal
    class="popupNews"
    :hide-footer="false"
    :hide-header="false"
    :id="id"
    size="xl"
    scrollable
    @show="showModal"
    @hidden="hiddenModal"
    ok-title="Guardar cambios"
    cancel-title="Salir"
    @ok="updateGallery"
  >
    <b-overlay :show="countImagesUpdate > 0" rounded="sm"></b-overlay>
    <template v-slot:modal-header>
      <div class="d-flex">
        <h2>Galeria de Imagenes</h2>
      </div>
      <div class="pointer justify-content-center">
        <b-button @click="changeImage" :pressed="false" variant="success"
          >Subir Imagenes</b-button
        >
      </div>
      <div class="pointer justify-self-center">
        <closeicon @click="closeModal()" />
      </div>
    </template>
    <div class="wrapper">
      <template v-for="item in listaGallery">
        <cardViewSelector
          :seleccion="true"
          :imageSrc="item.imagen"
          :key="item.id ? item.id : -1"
          :position="item.position"
          :imagesSelected="selectedImages"
          @selectedImage="selectedImage"
          @eliminarImagen="eliminarImagen"
        />
      </template>
      <template v-for="(item, index) in listaGallery">
        <cardViewSelector
          :seleccion="false"
          :imageSrc="item.imagen"
          :key="index"
          :index="item.id"
          :position="item.position"
          :imagesSelected="selectedImages"
          @selectedImage="selectedImage"
          @eliminarImagen="eliminarImagen"
        />
      </template>
      <input
        accept=".jpeg, .jpg, .png"
        @change="newImage"
        id="uploadImage"
        type="file"
        style="display: none"
        multiple
      />
    </div>
  </b-modal>
</template>

<script lang="ts">
import closeicon from "../../assets/components/close.vue";
import axios from "@/directives/axios.js";
import cardViewSelector from "@/components/dashboard/CardViewSelector.vue";
import imageCompression from "browser-image-compression";

export default {
  name: "selectorImagenes",
  props: {
    dataGallery: Array,
    idGallery: String,
    multiSelect: Boolean,
    id: String,
  },

  components: {
    closeicon,
    cardViewSelector,
  },

  data() {
    return {
      countImagesUpdate: 0,
      listaGallery: [],
      selectedImages: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    eliminarImagen(element) {
      this.$bvModal
        .msgBoxConfirm("¿Seguro que desea eliminar la imagen seleccionada?", {
          size: "sm",
          centered: true,
          footerClass: "justify-content-center",
          okTitle: "Si",
          cancelTitle: "No",
        })
        .then((option) => {
          if (option) {
            axios({
              method: "DELETE",
              url: `/imagenes/eliminarImagen`,
              withCredentials: true,
              data: {
                img: element.src,
                id: element.index,
              },
            })
              .then((result) => {
                this.listaGallery = result.data.imagenes;
                if (!this.idGallery) {
                  this.selectedImages = [];
                  if (Array.isArray(this.dataGallery))
                    this.selectedImages = this.listaGallery.filter((lista) =>
                      this.dataGallery.find(
                        (element) => lista.imagen == element
                      )
                    );
                }
              })
              .catch(() => {
                alert("Ocurrio un error inexperado, intentelo mas tarde");
              });
          }
        });
      // this.$bvModal.hide(this.id);
    },
    hiddenModal() {
      this.listaGallery = [];
    },
    showModal() {
      console.log("showModal - Selector imagenes");
      if (this.idGallery) {
        this.changeImages(this.idGallery);
      } else {
        this.changeImages();
        this.selectedImages = [];
      }
    },
    updateGallery() {
      if (this.idGallery) {
        this.$bvModal
          .msgBoxConfirm(
            "¿Seguro que desea Modificar las imagenes de la galeria?",
            {
              size: "sm",
              centered: true,
              footerClass: "justify-content-center",
              okTitle: "Si",
              cancelTitle: "No",
            }
          )
          .then((option) => {
            if (option) {
              axios({
                method: "POST",
                url: `/imagenes/actualizaImagenesGaleria`,
                withCredentials: true,
                data: {
                  id: this.idGallery,
                  listImages: this.selectedImages.map((element) => {
                    return element.id;
                  }),
                },
              })
                .then((value) => {
                  this.selectedImages = value.data.galerias;
                })
                .catch(() => {});
            }
          });
      } else {
        this.$emit("imagen", this.selectedImages);
      }
      this.$bvModal.hide(this.id);
    },
    changeImages(gallery) {
      axios({
        method: "GET",
        url: `/imagenes`,
        withCredentials: true,
      }).then((data) => {
        this.listaGallery = data.data.imagenes;
        if (gallery) {
          axios({
            method: "GET",
            url: `/imagenes/galeriaImagenes?id=${gallery}`,
            withCredentials: true,
          })
            .then((value) => {
              this.selectedImages = value.data.galerias.imagenes;
              if (Array.isArray(this.dataGallery))
                this.selectedImages = this.listaGallery.filter((lista) =>
                  this.dataGallery.find((element) => lista.imagen == element)
                );
            })
            .catch(() => {});
        } else {
          if (Array.isArray(this.dataGallery))
            this.selectedImages = this.listaGallery.filter((lista) =>
              this.dataGallery.find((element) => lista.imagen == element)
            );
        }
      });
    },
    //Metodo para la seleccion de las imagenes
    selectedImage(value) {
      console.log(value);
      if (value.isSelected) {
        this.selectedImages = this.selectedImages.filter(
          (element) => value.url !== element.imagen
        );
      } else {
        if (!this.multiSelect) {
          this.selectedImages = [];
        }
        this.selectedImages.push(
          this.listaGallery.find((element) => element.imagen === value.url)
        );
      }
    },
    changeImage() {
      document.getElementById("uploadImage").click();
    },
    closeModal() {
      this.$bvModal.hide(this.id);
    },
    newImage(value) {
      this.countImagesUpdate = 0;
      this.countImagesUpdate = value.target.files.length;
      for (let index = 0; index < this.countImagesUpdate; index++) {
        this.uploadImage(value.target.files[index]);
      }
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async uploadImage(e) {
      const file = e;
      // Opciones de compresión
      const options1 = {
        maxSizeMB: 0.1, // Tamaño máximo en MB => 0.2 = 200KB
        // maxWidthOrHeight: 800, // Redimensionar la imagen si es muy grande
        useWebWorker: true, // Usar web workers para mejorar el rendimiento
      };

      const options2 = {
        maxSizeMB: 1, // Tamaño máximo en MB => 0.2 = 200KB
        // maxWidthOrHeight: 800, // Redimensionar la imagen si es muy grande
        useWebWorker: true, // Usar web workers para mejorar el rendimiento
      };

      try {
        // Comprimir la imagen
        const compressedFile1 = await imageCompression(file, options1);
        const base64Image1 = await this.convertToBase64(compressedFile1);

        const compressedFile2 = await imageCompression(file, options2);
        const base64Image2 = await this.convertToBase64(compressedFile2);

        var imageBig = base64Image2;
        var imageLitel = base64Image1;
        var name = file.name;
        imageBig = imageBig.replace("data:image/jpg;base64,", "");
        imageBig = imageBig.replace("data:image/jpeg;base64,", "");
        imageBig = imageBig.replace("data:image/png;base64,", "");

        imageLitel = imageLitel.replace("data:image/jpg;base64,", "");
        imageLitel = imageLitel.replace("data:image/jpeg;base64,", "");
        imageLitel = imageLitel.replace("data:image/png;base64,", "");
        name = Math.trunc(Math.random() * (999 - 100) + 100).toString() + name;
        name = "sd" + name + ".jpg";
        name = name.replaceAll(" ", "_");
      } catch (error) {
        console.error("Error al comprimir la imagen:", error);
      }

      axios({
        method: "POST",
        url: `/imagenes/nuevaImagen`,
        withCredentials: true,
        data: {
          imageLitel: imageLitel,
          imageBig: imageBig,
          nameImage: name,
        },
      })
        .then((result) => {
          console.log("then-Subida Imagen");
          this.listaGallery = result.data.imagenes;
          this.countImagesUpdate--;
          if (!this.idGallery) {
            this.selectedImages = [];
            if (Array.isArray(this.dataGallery))
              this.selectedImages = this.listaGallery.filter((lista) =>
                this.dataGallery.find((element) => lista.imagen == element)
              );
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.d-flex {
  margin: 0 auto !important;
}

.pointer {
  margin-right: 10%;
}
</style>
