<template>
  <b-modal
    class="popupNews"
    :id="'modal'+id"
    :hide-footer="true"
    :hide-header="false"
    size="xl"
    scrollable
  >
    <template v-slot:modal-header>
      <div class="d-flex">
        <img
          style="height: 60px"
          :src="require('@/assets/logo 2.2-letras negras.png')"
          alt="logo Parroquia de la armada Nuestra señora del carmen"
        />
      </div>
      <div lass="pointer justify-self-center">
        <closeicon @click="closeModal()" />
      </div>
    </template>
    <div style="text-align: center" @click="urlClick()">
      <img
        style="width: 100%"
        :src="base+'/img/gallery/' + popUp.imagen"
        alt="logo Parroquia de la armada Nuestra señora del carmen"
      />
    </div>
  </b-modal>
</template>

<script lang="ts">
import closeicon from "../assets/components/close.vue";

export default {
  name: "PopUp",
  props: ["popUp", 'id'],

  components: {
    closeicon,
  },

  data() {
    return {
      base: location.protocol + "//" + location.hostname,
    };
  },
  watch: {},
  computed: {},
  methods: {
    urlClick() {
      if (this.popUp.url) {
        window.open(this.popUp.url, "_self");
      }
    },
    closeModal() {
      this.$bvModal.hide('modal'+this.id);
    },
  },
  mounted() {
    console.log(this.popUp);
    if (!this.$cookies.get(this.popUp.titulo)) {
      let path = window.location.hostname;
      this.$cookies.set(this.popUp.titulo, undefined, 60 * 60 * 0.2, path);
      this.$bvModal.show('modal'+this.id);
    }
  },
};
</script>
<style scoped>
.d-flex {
  margin: 0 auto !important;
}
</style>
