import { render, staticRenderFns } from "./popUp.vue?vue&type=template&id=af6b92f0&scoped=true"
import script from "./popUp.vue?vue&type=script&lang=ts"
export * from "./popUp.vue?vue&type=script&lang=ts"
import style0 from "./popUp.vue?vue&type=style&index=0&id=af6b92f0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af6b92f0",
  null
  
)

export default component.exports