<template>
  <b-modal
    scrollable
    class="popupNews"
    size="xl"
    :id="modalId"
    :hide-footer="false"
    :hide-header="false"
    @show="showModal"
    @hidden="hiddenModal"
  >
    <template v-slot:modal-header>
      <div class="d-flex">
        <h2>{{ "Editor PopUp" }}</h2>
      </div>
      <div class="pointer justify-self-center">
        <closeicon @click="closeModal()" />
      </div>
    </template>
    <b-row>
      <b-col>
        <div id="editor">
          <div class="">
            <div class="box">
              <input type="text" v-model="titlePopUp" placeholder="Titulo" />
            </div>
            <div class="box">
              <h5>Días de aparición:</h5>
              <b-form-datepicker
                reset-button
                :locale="'es'"
                :start-weekday="'1'"
                id="fechaReferencia"
                v-model="fechaReferencia"
                class="mb-2"
              ></b-form-datepicker>
            </div>
            <div>
              <b-table striped hover :items="fechas">
                <template v-slot:cell(id)="data">
                  <b-row>
                    <b-icon
                      @click="eliminarfila(data.value)"
                      scale="1"
                      variant="danger"
                      class="icons"
                      icon="trash-fill"
                    ></b-icon>
                  </b-row>
                </template>
              </b-table>
            </div>
            <b-row>
              <b-button @click="$bvModal.show('imagenPopUp')" variant="success"
                >Insertar imagen al popUp</b-button
              >
            </b-row>
            <b-row class="mt-2">
              <input type="text" v-model="urlEnlace" placeholder="Enlace" />
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col>
        <div v-if="imagenPopUpSrc != ''">
          <img
            style="width: 100%"
            :src="base + 'img/gallery/' + imagenPopUpSrc"
            alt="logo Parroquia de la armada Nuestra señora del carmen"
          />
        </div>
      </b-col>
    </b-row>
    <keep-alive>
      <selectorImagenes @imagen="imagenPopUp" id="imagenPopUp" />
    </keep-alive>
    <template v-slot:modal-footer>
      <div class="pointer justify-content-end">
        <b-button
          :disabled="enableSavePopup"
          variant="success"
          @click="savePopup"
          >Guardar</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script lang="ts">
import closeicon from "../../assets/components/close.vue";
import axios from "@/directives/axios.js";
import selectorImagenes from "./SelectorImages.vue";

export default {
  name: "editorPopUp",

  components: {
    closeicon,
    selectorImagenes,
  },
  data() {
    return {
      activeView: false,
      fechas: [],
      imagenPopUpSrc: "",
      fechaReferencia: "",
      titlePopUp: "",
      urlEnlace: undefined,
      base: location.protocol + "//" + location.hostname + "/",
    };
  },
  props: ["modalId", "popUpData"],
  mounted() {},
  computed: {
    enableSavePopup() {
      if (
        this.fechas.length > 0 &&
        this.imagenPopUpSrc != "" &&
        this.titlePopUp != ""
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    fechaReferencia: function (value) {
      this.fechas.push({ fecha: value, id: this.fechas.length + 1 });
    },
  },
  methods: {
    showModal() {
      if (this.popUpData) {
        this.imagenPopUpSrc = this.popUpData.imagen;
        this.titlePopUp = this.popUpData.titulo;
        this.urlEnlace = this.popUpData.url;
        this.fechas = this.popUpData.fechas.split(",").map((element, index) => {
          return { fecha: element, id: index + 1 };
        });
        this.activeView = true;
      } else {
        this.activeView = true;
      }
      this.$store.dispatch("changeAllImages");
    },
    //OK
    eliminarfila(data) {
      this.fechas = this.fechas.filter((element) => element.id != data);
    },
    //OK
    hiddenModal() {
      this.imagenPopUpSrc = "";
      this.fechaReferencia = undefined;
      this.titlePopUp = "";
      this.urlEnlace = "";
      this.fechas = [];
      this.activeView = false;
    },
    imagenPopUp(value) {
      console.log(value);
      if (value) {
        this.imagenPopUpSrc = value[0].imagen;
      } else {
        this.imagenPopUpSrc = "";
      }
    },
    savePop() {
      var cadenaFechas = this.fechas
        .map((element) => {
          return element.fecha;
        })
        .toString();
      axios({
        method: "post",
        url: `/popup/nuevoPopUp`,
        withCredentials: true,
        data: {
          titulo: this.titlePopUp,
          imagen: this.imagenPopUpSrc,
          fechas: cadenaFechas,
          url: this.urlEnlace,
        },
      })
        .then((value) => {
          let okValue = "popUp guardada Correctamente";
          this.$bvModal
            .msgBoxOk(okValue, {
              size: "sm",
              centered: true,
              footerClass: "justify-content-center",
              okTitle: "Aceptar",
            })
            .then(() => {
              this.closeModal();
              this.$emit("actualizaLista", value.data.todosPopUp);
            });
        })
        .catch((value) => {
          this.$bvModal.msgBoxOk(
            value.response.data.message
              ? value.response.data.message
              : "Error, intentelo mas tarde",
            {
              size: "sm",
              centered: true,
              footerClass: "justify-content-center",
              okTitle: "Aceptar",
            }
          );
        });
    },
    actualizarPopUp() {
      var cadenaFechas = this.fechas
        .map((element) => {
          return element.fecha;
        })
        .toString();
      axios({
        method: "post",
        url: `/popup/editarPopUp`,
        withCredentials: true,
        data: {
          id: this.popUpData.id,
          titulo: this.titlePopUp,
          imagen: this.imagenPopUpSrc,
          fechas: cadenaFechas,
          url: this.urlEnlace,
        },
      })
        .then((value) => {
          let okValue = "PopUp guardado Correctamente";
          let notOkValue =
            "Ocurrio un error durante el proceso de actualizacion.";

          this.$bvModal
            .msgBoxOk(value.status === 200 ? okValue : notOkValue, {
              size: "sm",
              centered: true,
              footerClass: "justify-content-center",
              okTitle: "Aceptar",
            })
            .then(() => {
              this.closeModal();
              this.$emit("actualizaLista", value.data.todosPopUp);
            });
        })
        .catch((value) => {
          this.$bvModal.msgBoxOk(
            value.response.data.message
              ? value.response.data.message
              : "Error, intentelo mas tarde",
            {
              size: "sm",
              centered: true,
              footerClass: "justify-content-center",
              okTitle: "Aceptar",
            }
          );
        });
    },
    savePopup() {
      this.$bvModal
        .msgBoxConfirm("¿Seguro que desea guardar los cambios?", {
          size: "sm",
          centered: true,
          footerClass: "justify-content-center",
          okTitle: "Si",
          cancelTitle: "No",
        })
        .then((chechResponse) => {
          if (chechResponse) {
            if (this.popUpData) {
              this.actualizarPopUp();
            } else {
              this.savePop();
            }
          }
        });
    },
    closeModal() {
      this.$bvModal.hide("editorPopUp");
    },
  },
};
</script>
<style scoped>
.d-flex {
  margin: 0 auto !important;
}
h5 {
  margin-right: 8px;
}
.box {
  margin-top: 5px;
  margin-bottom: 5px;
}
.datePosition {
  display: -webkit-inline-box;
}
.datePosition div {
  margin-right: 5px;
}

input,
select {
  border-radius: 15px;
  box-sizing: border-box;
  width: 100%;
  border: 2px solid rgb(115, 123, 131);
  height: 35px;
}
.pointer :hover {
  cursor: pointer;
}
.timeHour {
  padding: 0;
  margin: 0;
}
.custom-color-menu {
  flex-wrap: wrap;
  min-width: 152px;
  width: 152px;
  max-width: 152px;
}
.btn-group,
btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  vertical-align: middle;
}
@media screen and (min-width: 990px) {
}
</style>
