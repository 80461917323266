import axios from 'axios';

// Configuración global de Axios
axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
    withCredentials: true,
    //baseURL: 'http://localhost/apiArmadaMadrid1/public/api',
    baseURL: 'https://apiweb.parroquiaarmada.com/api', // URL base de la API
    // timeout: 5000, // Tiempo de espera para las solicitudes
  });

export default axiosInstance;