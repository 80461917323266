<template>
  <div>
    <sidebar @pag="pagina" />
    <component :is="view" ref="paymentRef" />
  </div>
</template>

<script>
import sidebar from "@/components/dashboard/Sidebar.vue";
import panelNoticias from "@/views/dashboard/VistaEditorNoticias.vue";
import panelNoticiasCaritas from "@/views/dashboard/VistaEditorNoticiasCaritas.vue";
import createGallery from "@/views/dashboard/EditorGalerias.vue";
import popUpControlEdit from "@/views/dashboard/PopUpControlEdit.vue";
import editorHorarios from "@/views/dashboard/EditorHorarios.vue"

export default {
  name: "dashboardView",
  props: [],
  components: {
    sidebar,
    panelNoticias,
    createGallery,
    popUpControlEdit,
    editorHorarios,
    panelNoticiasCaritas
  },

  data() {
    return {
      stateToken: undefined,
      collapsed: false,
      pag: "noticias",
    };
  },
  watch: {},
  methods: {
    pagina(value) {
      this.pag = value;
    },
    onCollapse(c) {
      if (c) {
        document.getElementById("bodyDasBoard").style.transition = "0.5s";
        document.getElementById("bodyDasBoard").style.marginLeft = "60px";
      } else {
        document.getElementById("bodyDasBoard").style.transition = "0.5s";
        document.getElementById("bodyDasBoard").style.marginLeft = "270px";
      }
      this.collapsed = c;
    },
    checkToken() {
      if (!this.$cookies.get("token_parmada")) {
        clearInterval(this.stateToken);
        this.$bvModal
          .msgBoxOk("Sesion Caducada", {
            size: "sm",
            centered: true,
            footerClass: "justify-content-center",
            okTitle: "Aceptar",
          })
          .then(() => {
            this.$router.push({ name: "login" });
          });
      }
    },
  },
  computed: {
    view() {
      let vista = panelNoticias;
      switch (this.pag) {
        case "noticias":
          vista = panelNoticias;
          break;
        case "noticiasCaritas":
          vista = panelNoticiasCaritas;
          break;
        case "galeria":
          vista = createGallery;
          break;
        case "PopUp":
          vista = popUpControlEdit;
          break;
        case "Horarios":
          vista = editorHorarios;
          break;
        default:
          break;
      }
      return vista;
    },
  },
  mounted() {
    this.$store.dispatch("initialDataChange");
    this.stateToken = setInterval(this.checkToken, 300000);
    // 300000
  },
  created() {
    if (
      !this.$cookies.get("token_parmada") ||
      this.$cookies.get("token_parmada") === undefined
    ) {
      this.$router.push({ name: "login" });
    }
  },
};
</script>
<style>
#view {
  padding-left: 0;
}
#view.collapsed {
  padding-left: 50px;
}
.v-sidebar-menu.vsm_expanded {
  max-width: 250px !important;
}
/* .v-sidebar-menu{

} */
</style>
