<template>
  <b-modal
    class="popupNews"
    size="xl"
    :id="modalId"
    :hide-footer="true"
    :hide-header="false"
  >
    <template v-slot:modal-header>
      <div class="d-flex">
        <img style="height: 60px" :src="require('@/assets/logo 2.2-letras negras.png')" alt="logo Parroquia de la armada Nuestra señora del carmen"/>
      </div>
      <div class="pointer justify-self-center">
        <closeicon @click="closeModal" />
      </div>
    </template>
    <b-col class="text-center">
      <h3 id="header-contact">Contacto</h3>
      <b-row class="mt-2">
        <b-col>
          <b-row><b-icon style="color: green" class="icons h1 mt-5" icon="telephone" /></b-row>
          <b-row class="mt-2"><a href="tel:+34917674508" class="text-center">917674508</a></b-row>
        </b-col>
        <b-col>
            <b-row><b-icon style="color: green" class="icons h1 mt-5" icon="envelope" /></b-row>
            <b-row class="mt-2"><a href="mailto:parroquiaarmada@gmail.com" class="text-center">parroquiaarmada@gmail.com</a></b-row>
        </b-col>
        <b-col>
            <b-row><b-icon style="color: green" class="icons h1 mt-5" icon="map" /></b-row>
            <b-row class="mt-2"><p class="text-center">C. de Serrano Galvache, 7, Cdad. Lineal - 28033 Madrid, España</p></b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-col class="text-center mt-5">
      <h3 id="header-contact">Caritas Castrense Parroquial</h3>
      <b-row class="mt-2">
        <b-col>
          <b-row><b-icon style="color: green" class="icons h1 mt-5" icon="telephone" /></b-row>
          <b-row class="mt-2"><a href="tel:+34636513858" class="text-center">636513858</a></b-row>
        </b-col>
        <b-col>
            <b-row><b-icon style="color: green" class="icons h1 mt-5" icon="envelope" /></b-row>
            <b-row class="mt-2"><a href="mailto:parroquiaarmada@gmail.com" class="text-center">parroquiaarmada@gmail.com</a></b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-modal>
</template>

<script lang="ts">
// import Vue from 'vue';
import closeicon from "../assets/components/close.vue";

export default {
  name: "modalContacto",

  components: {
    closeicon,
  },

  data() {
    return {
      checkState: false,
      phoneContact: undefined,
      emailContac: undefined,
    };
  },

  props: ["modalId"],

  computed: {},
  methods: {
    checkStateChange() {
      this.checkState = !this.checkState;
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
<style>
#header-contact {
  border-bottom: 1px solid;
}
</style>
<style scoped>
.d-flex {
  margin: 0 auto !important;
}
</style>
