<template>
  <b-col>
    <b-row>
      <b-button @click="modalEditor()" variant="success">Nuevo PopUp</b-button>
    </b-row>
    <div v-if="lista.length > 0" class="wrapper">
      <!-- <template v-for="(item, index) in lista"> -->
      <cardPopUp
        v-for="(item, index) in lista"
        :imageSrc="item.imagen"
        :popuptitle="item.titulo"
        :idPopUp="item.id"
        :key="index"
        @editPopUp="editarPopUp"
        @eliminarPopUp="eliminarPopUp"
      />
      <!-- </template> -->
    </div>
    <editorPopUp
      modalId="editorPopUp"
      :popUpData="popUpData"
      @actualizaLista="actualizaLista"
    />
  </b-col>
</template>

<script>
import axios from "@/directives/axios.js";
import editorPopUp from "@/components/dashboard/EditorPopUp.vue";
import cardPopUp from "@/components/dashboard/CardPopUp.vue";

export default {
  name: "vistaNoticia",
  props: [],
  components: { editorPopUp, cardPopUp },
  data() {
    return {
      lista: [],
      popUpData: undefined,
      newBody: undefined,
      title: "",
      onlyImage: [],
      idGallery: undefined,
      cuerpoNoticia: "",
      imagePortada: ""
    };
  },
  methods: {
    eliminarPopUp(element) {
      this.$bvModal
        .msgBoxConfirm("¿Desea eliminar el PopUp seleccionado?", {
          size: "sm",
          centered: true,
          footerClass: "justify-content-center",
          okTitle: "Aceptar",
        })
        .then((chechResponse) => {
          if (chechResponse) {
            axios({
              method: "delete",
              url: `/popup/eliminarPopup?id=${element.idPopUp}`,
              withCredentials: true
            })
              .then((data) => {
                this.lista = data.data.todosPopUp;
                this.actualizaLista(data.data.todosPopUp);
              })
              .catch(() => {
              });
          }
        });
    },
    actualizaLista(elements) {
      this.lista = elements;
    },
    editarPopUp(id) {
      this.popUpData = this.lista.find((element) => (element.id = id.idPopUp));
      this.$nextTick(() => this.$bvModal.show("editorPopUp"));
    },
    modalEditor() {
      this.popUpData = undefined;
      this.$nextTick(() => this.$bvModal.show("editorPopUp"));
    },
    getPopUps() {
      axios({
        method: "get",
        url: `/popup/todosPopup`,
        withCredentials: true,
      })
        .then((data) => {
          this.lista = data.data.todosPopUp;
        })
        .catch(() => {
        });
    },
  },
  mounted() {
    this.getPopUps();
  },
};
</script>
<style scoped>
.bodyCarrete {
  padding-top: 120px;
}
.backButton img {
  width: 45px;
  display: flex;
  margin-left: 20px;
  transition: 1s;
}
.backButton :hover {
  width: 55px;
  transition: 1s;
}
.backButton :hover {
  cursor: pointer;
}
.backButton {
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
}
.galleryButton {
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 0;
}
.cuerpoNoticia {
  margin-left: 84px;
  margin-right: 84px;
}
</style>
